import React, { useContext, useEffect } from "react";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon } from "../../../helper/icons";
import { useRef } from "react";
import Scroller from "../../../components/Scroller/ScrollBar";
import { ThemeContext } from "../../../helper/context";
import { useState } from "react";
import { notify } from "../../../Routes";
import Button from "../../../components/Button/Button";
import { axiosApiInstance } from "../../../helper/request";
import { useHistory } from "react-router-dom";
// let selectedType = "monthly"
let extraData = {
  totalCount: 0,
  pricing_id: "",
};
let _count = 0;

export default function UpgradeLicense2({
  RenewLicence,
  setRenewLicence,
  SelectedLicenseDetails,
  setSelectedLicenseDetails,
  selectedDevice,
  _mapping_id,
  _license_name,
  deviceName,
  manufacturerName,
  serialNumber,
  clickedThumbnail,
  clickedAppName,
  licenseType,
  clickedAppId,
}) {
  const history = useHistory();

  const userScroll = useRef();
  const { getCart } = useContext(ThemeContext);
  const totalRef = useRef(null);
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];
  const [IsActive, setIsActive] = useState("monthly");
  const [buttonName, setButtonName] = useState("buy");

  const postData = () => {
    let license_details = {};
    for (let i = 0; i < SelectedLicenseDetails.length; i++) {
      license_details[SelectedLicenseDetails[i].attribute_name] = parseInt(
        SelectedLicenseDetails[i][IsActive].count
      );
    }

    let body = {};
    licenseType === "app"
      ? (body = {
          device: {
            serial_number: serialNumber
              ? serialNumber
              : selectedDevice.serial_number,
            device_name: deviceName ? deviceName : selectedDevice.device_name,
          },
          usecase: {
            "usecase_id": clickedAppId,
            usecase_name: clickedAppName,
            usecase_image: clickedThumbnail,
          },
          manufacturer_license_mapping_id: _mapping_id,
          quantity: 1,
          item_type: "usecase",
          item_details: {
            license_type: "standard", //add, upgrade or renew
            license_duration: IsActive,
            license_details, // att:count
            license_name: _license_name,
          },
        })
      : (body = {
          device: {
            serial_number: serialNumber
              ? serialNumber
              : selectedDevice.serial_number,
            device_name: deviceName ? deviceName : selectedDevice.device_name,
          },
          manufacturer_license_mapping_id: _mapping_id,
          quantity: 1,
          item_type: "device",
          item_details: {
            license_type: "standard", //add, upgrade or renew
            license_duration: IsActive,
            license_details, // att:count
            license_name: _license_name,
          },
        });
    axiosApiInstance
      .post("order/cart", body)
      .then((res) => {
        setRenewLicence(false);
        getCart();
        setTimeout(() => {
          totalRef.current.textContent = "$" + _count;
        }, 10);
        notify({
          msg: "Product added to cart",
          type: "success",
        });
      })
      .catch((err) => {
        if (err.response.status === 409) {
          notify({
            msg: "Product already exists in cart",
          });
        } else if(err.response.status === 403){
          notify({
            type: "error",
            msg: err?.response?.data?.detail
          });
        } else{
          notify(({}))
        }
      })
      .finally(() => {
        setRenewLicence(false);
      });
  };

  useEffect(() => {
    if (SelectedLicenseDetails.length > 0) {
      let _data = [...SelectedLicenseDetails];
      _count = 0;
      for (let i = 0; i < _data.length; i++) {
        let multiply = _data[i][IsActive]?.amount * _data[i][IsActive].count;
        _count += multiply;
      }
      _count = _count.toFixed(2);
      extraData.totalCount = _count;
      totalRef.current.textContent = "$" + _count;
    }
  }, [SelectedLicenseDetails, IsActive]);

  return (
    <AnimatedModal
      modalOpen={RenewLicence}
      className="upgrade_licence_modal__2"
    >
      {console.log(clickedAppId, "clickedAppId")}
      {console.log(deviceName, "deviceName")}
      <div
        className="close_otp_modal"
        onClick={() => {
          setRenewLicence(false);
        }}
      >
        {closeIcon()}
      </div>
      <h1 className="select_atr_header">Select Attribute Quantity</h1>
      <div className="upgrade_license_table_wrapper">
        <Scroller autoHeightMax="29vw" ref={userScroll}>
          <table>
            <tr>
              {arr.map((header, index) => {
                return header === "attribute_name" ? (
                  <th
                    style={{
                      color: "#0000004D",
                      fontWeight: 500,
                      fontSize: "1vw",
                      paddingLeft: "1vw",
                      textAlign: "left",
                    }}
                  >
                    Attributes
                  </th>
                ) : (
                  <th
                    onClick={() => {
                      setIsActive(header);
                    }}
                    style={{
                      cursor: "pointer",
                      // opacity: IsActive !== header && "0.4",
                    }}
                    className="atr_duration_headers"
                    key={header + index}
                  >
                    {header.replace(/_/g, " ")}
                  </th>
                );
              })}
            </tr>
            {SelectedLicenseDetails.map((item, idx) => {
              return (
                <tr>
                  {arr.map((header, idx2) => {
                    return header === "attribute_name" ? (
                      <td className="atr_name_columns">{item[header]}</td>
                    ) : (
                      <td
                        style={{
                          // opacity: IsActive !== header && "0.4",
                          borderRight:
                            IsActive === header && "2px solid var(--primary)",
                          borderLeft:
                            IsActive === header && "2px solid var(--primary)",
                          borderBottom:
                            IsActive === header && "2px solid var(--primary)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsActive(header);
                        }}
                        className="atr_duration_columns"
                      >
                        {console.log(clickedThumbnail, "clickedThumbnail")}
                        <div className="_pricing_div">
                          <p style={{ display: "flex", alignItems: "center" }}>
                            Price:
                          </p>
                          <p
                            style={{
                              color: "var(--primary)",
                              fontSize: "1.4vw",
                              // opacity: item[header] === "base" && 0
                            }}
                          >
                            ${item[header]?.amount}
                          </p>
                        </div>
                        <div
                          // style={{
                          //   opacity:
                          //     item["attribute_name"] === "base" ? "0.4" : "1",
                          // }}
                          className="_quantity_div"
                        >
                          {item["attribute_name"].toLowerCase() !== "base" && (
                            <>
                              {" "}
                              <p>Quantity: </p>
                              <input
                                className="_count_input__"
                                // disabled={item["attribute_name"] === "base"}
                                disabled={IsActive !== header}
                                value={item[header].count}
                                onChange={(e) => {
                                  let { value } = e.target;
                                  if (value != 0 || value === "") {
                                    let _data = [...SelectedLicenseDetails];
                                    _data[idx][header].count = value;
                                    setSelectedLicenseDetails([..._data]);
                                  }
                                }}
                                onBlur={() => {
                                  let _data = [...SelectedLicenseDetails];
                                  if (_data[idx][header].count === "") {
                                    _data[idx][header].count = "1";
                                    setSelectedLicenseDetails([..._data]);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </Scroller>
      </div>
      <div className="total_price_div">
        <div className="total_price_sub_div">
          <p className="total_pricing_para">
            {SelectedLicenseDetails.length} Attributes |
            <span className="total_count" ref={totalRef}></span>
          </p>
          <p className="extra_charges_para">
            Extra charges for base applied <p className="view_pill">View</p>
          </p>
        </div>
        <div className="addtocart_buynow_wrapper">
          <Button
            id="cart_btn"
            name="Add to Cart"
            style={{ width: "10vw", padding: "0.3vw", height: "3vw" }}
            onClick={() => {
              postData();
            }}
          />
          <Button
            id="cart_btn"
            name="Buy now"
            style={{ width: "10vw", padding: "0.3vw", height: "3vw" }}
            onClick={() => {
              postData();
              history.push("/cart");
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
