import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Scrollbars from "react-custom-scrollbars";
import Marquee from "../Marquee/Marquee";
import { Radio } from "../Radio/Radio";
import useClickOutside from "../../helper/useClickOutside";
import "./MultiSelectDropdown.scss";
export default function MultiSelectDropdown({
  optionsList,
  handleOption,
  handleObjectOption,
  defaultText,
  label,
  error,
  id,
  onMouseDown,
  className,
  isLoading,
  onFocus,
  style,
  isObject,
}) {
  const primaryRef = useRef();
  const [defaultSelectText, setDefaultSelectText] = useState(defaultText || []);
  const [showOptionList, setshowOptionList] = useState(false);

  const primaryClose = useCallback(() => {
    document.getElementById(id).classList.remove("active__");
    setTimeout(() => {
      setshowOptionList(false);
    }, 200);
  }, []);
  useClickOutside(primaryRef, primaryClose);

  useEffect(() => {
    if (showOptionList) {
      setTimeout(() => {
        document.querySelector("#" + id).classList.add("active__");
      }, 100);
    }
  }, [showOptionList]);

  useEffect(() => {
    Array.isArray(defaultText)
      ? setDefaultSelectText(defaultText)
      : setDefaultSelectText([]);
  }, [defaultText]);

  const handleListDisplay = () => {
    if (onFocus) {
      onFocus();
    }
    setshowOptionList(!showOptionList);
  };

  const handleOptionClick = (value) => {
    let _defaultSelectText = [...defaultSelectText];
    if (typeof defaultSelectText === "string") {
      _defaultSelectText = [];
    }
    if (defaultSelectText.includes(value)) {
      let _index = _defaultSelectText.indexOf(value);
      _defaultSelectText.splice(_index, 1);
    } else {
      _defaultSelectText.push(value);
    }
    if (_defaultSelectText.length) {
      handleOption([..._defaultSelectText]);
      setDefaultSelectText([..._defaultSelectText]);
    } else {
      handleOption([]);
      setDefaultSelectText([]);
    }
  };

  const renderActive = (name) => {
    const found = defaultSelectText.find((element) => element.name === name);
    if (found) return true;
    return false;
  };

  return (
    <div
      className={
        className
          ? "custom-multi-select-container " + className
          : "custom-multi-select-container"
      }
      style={style}
    // onMouseDown={onMouseDown}
    >
      {label && <p className="dd-label">{label}</p>}
      <div
        className={error ? "selected-text input__error" : "selected-text"}
        onClick={isLoading ? null : () => handleListDisplay()}
        // title={defaultSelectText.join()}
        id={"m_" + id}
      >
        <div
          className="_text"
          // onMouseLeave={() => {
          //   let parent = document.getElementById("m_" + id);
          //   parent.querySelector(".marquee").style.animationName = "none";
          //   parent.querySelector(".marquee2").style.animationName = "none";
          //   parent.querySelector(".marquee")?.offsetWidth?.();
          //   parent.querySelector(".marquee2")?.offsetWidth?.();
          //   parent.querySelector(".marquee").style.animationName = "scroll";
          //   parent.querySelector(".marquee2").style.animationName = "scroll";
          // }}
          onMouseEnter={() => {
            let parent = document.getElementById("m_" + id);
            parent.querySelector(".marquee").style.animationName = "scroll";
            parent.querySelector(".marquee2").style.animationName = "scroll";
          }}
        >
          <Marquee gradient={false} play={false}>
            {Array.isArray(defaultSelectText) && defaultSelectText.length
              ? isObject
                ? defaultSelectText.map((item) => {
                  if (defaultSelectText.length > 1) {
                    return item.name + ", ";
                  } else {
                    return item.name;
                  }
                })
                : defaultSelectText.join(", ")
              : ""}
          </Marquee>
          {defaultSelectText.length > 0 ? "" : "Choose option"}
        </div>
        {expand(showOptionList)}
      </div>
      {showOptionList && (
        <div className="select-options" ref={primaryRef} id={id}>
          <Scrollbars
            renderThumbHorizontal={({ style, ...props }) => {
              const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(35, 49, 86, 0.8)",
              };
              return <div style={{ ...style, ...thumbStyle }} {...props} />;
            }}
            renderThumbVertical={({ style, ...props }) => {
              const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                width: "3px",
              };
              return <div style={{ ...style, ...thumbStyle }} {...props} />;
            }}
            autoHeight
            autoHeightMax="18vh"
          >
            {isObject ? (
              <div className="multi-parent">
                {optionsList.map((option) => {
                  return (
                    <button
                      type="button"
                      className={
                        // defaultSelectText?.includes(option)
                        renderActive(option.name)
                          ? "custom-select-option active_option"
                          : "custom-select-option"
                      }
                      key={option.name}
                      onClick={() =>
                        handleObjectOption({ name: option.name, id: option.id })
                      }
                    >
                      {option.name}
                      <Radio
                        style={{ pointerEvents: "none" }}
                        // name="getAlertName"
                        id="checkbox_001"
                        key={"index_" + "index"}
                        // checked={isAllSelected}
                        // checked={defaultSelectText?.includes(option)}
                        checked={renderActive(option.name)}
                        onClick={() => {
                          // isAllSelected = !isAllSelected;
                          // if (isAllSelected) {
                          //   let _selected = [...selectedRow];
                          //   _selected.length = 0;
                          //   const _id = data.map((item) => item.Ticket_number);
                          //   _selected = [..._id];
                          //   setselectedRow([..._selected]);
                          //   setDisableButtons(false);
                          // } else {
                          //   setselectedRow([]);
                          //   setDisableButtons(true);
                          // }
                        }}
                      />
                      {/* <input
                  type="checkbox"
                  checked={defaultSelectText?.includes(option)}
                  className="multi-checkbox"
                /> */}
                    </button>
                  );
                })}
              </div>
            ) : (
              <div className="multi-parent">
                {optionsList.map((option) => {
                  return (
                    <button
                      type="button"
                      className={
                        defaultSelectText?.includes(option)
                          ? "custom-select-option active_option"
                          : "custom-select-option"
                      }
                      data-name={option}
                      key={option}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                      <Radio
                        style={{ pointerEvents: "none" }}
                        // name="getAlertName"
                        id="checkbox_001"
                        key={"index_" + "index"}
                        // checked={isAllSelected}
                        checked={defaultSelectText?.includes(option)}
                        onClick={() => {
                          // isAllSelected = !isAllSelected;
                          // if (isAllSelected) {
                          //   let _selected = [...selectedRow];
                          //   _selected.length = 0;
                          //   const _id = data.map((item) => item.Ticket_number);
                          //   _selected = [..._id];
                          //   setselectedRow([..._selected]);
                          //   setDisableButtons(false);
                          // } else {
                          //   setselectedRow([]);
                          //   setDisableButtons(true);
                          // }
                        }}
                      />
                      {/* <input
                  type="checkbox"
                  checked={defaultSelectText?.includes(option)}
                  className="multi-checkbox"
                /> */}
                    </button>
                  );
                })}
              </div>
            )}
          </Scrollbars>
        </div>
      )}
    </div>
  );
}

const expand = (showOptionList) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6.881}
    height={4.011}
    viewBox="0 0 6.881 4.011"
    className={showOptionList ? "drop_icon list_open" : "drop_icon"}
  >
    <g id="ic-chevron-down" transform="translate(1.061 0.75)">
      <g id="Layer">
        <g id="Vrstva_127" data-name="Vrstva 127">
          <path
            id="Vector"
            d="M0,2.2,2.055.145a.5.5,0,0,1,.7,0l2,2"
            fill="none"
            stroke="#151515"
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth={1.5}
          />
        </g>
      </g>
    </g>
  </svg>
);
//usage
//  <MultiSelectDropdown
//    optionsList={multiOption}
//    label="TITLE"
//    id="custom"
//    defaultText={multiSelectedValue}
//    handleOption={(data) => setmultiSelectedValue([...data])}
//  />;

// const [multiSelectedValue, setmultiSelectedValue] = useState([
//   "Oliver",
//   "Van",
//   "April",
//   "Ralph",
// ]);
// const [multiOption, setmultiOption] = useState([
//   "Oliver",
//   "Van",
//   "April",
//   "Ralph",
//   "Omar",
//   "Carlos",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ]);
