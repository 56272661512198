import React, { useEffect, useState } from "react";
import { FormCard, FormHeader } from "../../../components/FormCard/FormCard";
import InputBox from "../../../components/Inputbox/InputBox";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { API_URL, axiosApiInstance, OTP_TIME } from "../../../helper/request";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import Loading from "../../../components/Loading/Loading";
import { encrypt, encryptStorage } from "../../../helper/storage";
import { closeIcon } from "../../../helper/icons";
import OTP from "../../../components/OTP/OTP";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import ResetPassword from "../../../components/ResetPassword/ResetPassword";
import { notify } from "../../../Routes";
// import ReCAPTCHAV2 from "react-google-recaptcha";

let _lData__ = encryptStorage.getItem("VID");
let resetCounter = null;
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
export default function AuthLogin() {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  //otp
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [Stop, setStop] = useState(false);
  const [Confirm, setConfirm] = useState(false);
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");

  const [ShowAlert, setShowAlert] = useState(false);

  const [ShowResetEmail, setShowResetEmail] = useState(false);
  const [ShowResetPassword, setShowResetPassword] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [errors, setErrors] = useState({
    isUsernameEmpty: false,
    isPasswordEmpty: false,
  });
  const [oldType, setOldType] = useState("password");
  // const [captchaValue, setcaptchaValue] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [dummyCounter, setDummyCounter] = useState(false);

  const emailValidation = () => {
    if (
      Email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePassword = (type) => {
    if (!type) {
      setOldType("password");
    } else {
      setOldType("text");
    }
  };

  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  const getNotification = () => {
    themeContext.getNotification((res) => {
      if (res) {
        history.push("/home");
      }
    });
  };
  const getRequest = () => {
    themeContext.getRequest((res) => {
      if (res) {
        getNotification();
      }
    });
  };

  const getCart = () => {
    themeContext.getCart();
  };

  const getNavbarData = (user) => {
    axiosApiInstance
      .get("user/navbar")
      .then((res) => {
        let { profile_pic, username, roles } = res.data;
        let keys = Object.keys(roles);
        let arr = [];
        let pending = [];
        let suspended = [];
        let isRejected = false;
        for (let i = 0; i < keys.length; i++) {
          if (roles[keys[i]] !== "pending" && roles[keys[i]] !== "rejected") {
            arr.push(keys[i]);
          }
          if (roles[keys[i]] === "pending" || roles[keys[i]] === "suspended") {
            pending.push(keys[i]);
          }
          if (roles[keys[i]] === "suspended") {
            suspended.push(keys[i]);
          }
          if (roles[keys[i]] === "rejected") {
            isRejected = true;
          }
        }
        let _data = {
          ...user,
          profile_pic,
          rejected: isRejected,
          username,
          roles: arr,
          pending: [...pending],
          suspended: [...suspended],
        };
        if (arr.includes("superadmin")) {
          _data.current = "superadmin";
        } else {
          _data.current = "user";
        }

        encryptStorage.setItem("UID", _data);
        themeContext.setRole({
          all: [...arr],
          current: _data.current,
          pending: [...pending],
          suspended: [...suspended],
          rejected: isRejected,
        });

        if (arr.includes("superadmin")) {
          getRequest();
        } else {
          getCart();
          getNotification();
        }
      })
      .catch((err) => {
        msg.errorText = "Failed to fetch user details!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postData = () => {
    // if (
    //   localStorage.getItem("timer") !== "1" &&
    //   localStorage.getItem("timer") !== null
    // ) {
    //   return;
    // }
    let _errors = { ...errors };
    if (Username === "") _errors["isUsernameEmpty"] = true;
    if (Password === "") _errors["isPasswordEmpty"] = true;
    let error = false;
    // if (captchaValue === "") {
    //   error = true;
    //   notify({
    //     msg: "Captcha validation is required",
    //   });
    // }
    setErrors({ ..._errors });
    if (Object.values(_errors).includes(true) || error === true) {
      // if (Object.values(_errors).includes(true)) {
      return;
    }
    setLoadingScreen(true);
    axios
      .post(
        API_URL + "auth/login",
        {},
        {
          headers: {
            field: Username,
            password: Password,
            key: "captcha",
          },
        }
      )
      .then((res) => {
        let _data = { ...res.data };
        localStorage.removeItem("timer");
        encryptStorage.setItem("UID", _data);
        getNavbarData(_data);
      })
      .catch((err) => {
        console.log(err)
        setLoadingScreen(false);
        if (err.response.status) {
          setErrorResponse(err.response.data.detail);
          if (err.response.data.detail.includes("minute")) {
            localStorage.setItem(
              "timer",
              err.response.data.detail.match(/(\d+)/)[0] * 60
            );
          }
          notify({
            msg: err.response.data.detail,
          });
        } else {
          notify({
            msg: "Invalid Credential!",
          });
        }
      });
  };

  const postResetUserPassword = (_password) => {
    setLoadingScreen(true);
    let lData = encryptStorage.getItem("UDATA");
    axios
      .post(API_URL + "user/forgetpassword", {
        current_password: _password,
        email: lData.destination,
      })
      .then((res) => {
        msg.errorText = "Password changed successfully!";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setPassword("");
        setEmail("");
        setShowResetPassword(false);
        setTimeout(() => {
          encryptStorage.removeItem("UDATA");
          encryptStorage.removeItem("VID");
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postResetEmail = () => {
    if (!emailValidation()) {
      let ele = document.getElementById("reset_email_Address");
      ele.classList.add("error_shake", "input__error");
      return;
    }
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/resetPassword", {
        email: Email,
      })
      .then((res) => {
        msg.errorText = "OTP sent to your registered Email Address.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setEmail("");
        setShowResetEmail(false);
        setTimeout(() => {
          encryptStorage.setItem("VID", {
            counter: OTP_TIME,
            destination: Email,
          });
          setShowAlert(false);
          setShowOTP(true);
        }, 3000);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          // msg.errorText = "Too many OTP Attempts";
          msg.errorText = err.response.data.detail;
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
        } else {
          msg.errorText = "Email does not exist!";
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
        }

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postOTP = (otp) => {
    setLoadingScreen(true);
    let lData = encryptStorage.getItem("VID");
    let obj = {
      //  username: lData.username,
      email: lData.destination,
      otp,
    };
    let udata = encryptStorage.getItem("VID");
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        // encryptStorage.removeItem("VID");
        encryptStorage.setItem("UDATA", udata);
        setStop(true);
        setShowOTP(false);
        setShowResetPassword(true);
      })
      .catch((err) => {
        let _err = err.response.data.detail;
        msg.errorText = err.response.data.detail;
        // if (_err == "Otp Expired") {
        //   msg.errorText = "OTP Expired! Please generate a new one.";
        // } else {
        //   msg.errorText = "Invalid OTP. Please Try Again!";
        // }
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postResendOTP = (otp) => {
    let _lData = encryptStorage.getItem("VID");
    let obj = {
      // username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/resetPassword", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          resetCounter();
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    const html = document.querySelector("html");
    html.classList.add("light-theme");
    if (_lData__) {
      setShowOTP(true);
      setStop(false);
    } else {
      if (encryptStorage.getItem("UDATA")) {
        setShowResetPassword(true);
      }
    }
  }, [localStorage.getItem("timer")]);
  const useCountDown = (start) => {
    const [counter, setCounter] = useState(start);
    useEffect(() => {
      if (counter <= 0) {
        return;
      }
      setTimeout(() => {
        setCounter(counter - 1);
        counter === 1 && setDummyCounter(true); //setting a dummy state so that the counter  disappears and sign in button gets enabled
        localStorage.setItem("timer", counter);
      }, 1000);
    }, [counter]);

    return counter;
  };
  const ShowCountdown = ({ seconds }) => {
    const timeLeft = useCountDown(seconds);
    return (
      <div className="login_counter">
        <p className="counter_login_header">Can't login for</p>
        <spn className="counter_login_number">
          {(timeLeft >= 600 ? " " : "0") +
            Math.floor(timeLeft / 60) +
            ":" +
            (timeLeft % 60
              ? timeLeft % 60 > 9
                ? timeLeft % 60
                : "0" + (timeLeft % 60)
              : "00")}
        </spn>
      </div>
    );
  };

  return (
    <div className="__auth_login__ light-theme">
      <FormCard>
        <div className="card_holder">
          <FormHeader name="Sign in to" bold="Marketplace." />
          <p className="sub">Welcome, to the simplest AI platform.</p>
          <Link to={"/register"}>
            <p className="new_account">Create new account ?</p>{" "}
          </Link>
          <InputBox
            id="emailAddress"
            header="Username / Email / Phone"
            icon={emailIcon()}
            style={{ paddingLeft: "3vw" }}
            onChange={(e) => {
              const value = e.target.value;
              setUsername(value);
            }}
            error={errors["isUsernameEmpty"]}
            value={Username}
            onFocus={() => clearError("isUsernameEmpty")}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                postData();
              }
            }}
            autoFocus
          />
          <InputBox
            id="Password"
            header="Password"
            type={oldType}
            password
            typeValue={(data) => handlePassword(data)}
            // icon={<img src={visibilityoff} className="input_icon" />}
            icon={passIcon()}
            style={{ paddingLeft: "3vw" }}
            onChange={(e) => setPassword(e.target.value)}
            error={errors["isPasswordEmpty"]}
            value={Password}
            onFocus={() => clearError("isPasswordEmpty")}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                postData();
              }
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span />
            <p className="forgot" onClick={() => setShowResetEmail(true)}>
              Forgot your password?
            </p>
          </div>
          <div style={{ height: "1.5vw" }} />
          {/* <div className="recaptcha_login">
            <ReCAPTCHAV2
              data-type="image"
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={(value) => {
                setcaptchaValue(value);
              }}
              // size="compact"
            />
          </div> */}
          <p>
            {localStorage.getItem("timer") !== "1" &&
            localStorage.getItem("timer") !== null ? (
              <ShowCountdown seconds={localStorage.getItem("timer")} />
            ) : (
              ""
            )}
          </p>
          <Button
            type="gradient"
            bg
            name="Sign In"
            onClick={postData}
            disabled={
              localStorage.getItem("timer") !== "1" &&
              localStorage.getItem("timer") !== null
            }
            // onClick={() => {
            //   let arr = ["user", "manufacturer"];
            //   let ciphertext = encrypt(JSON.stringify(arr));
            //   history.push("/redirect?link=" + encodeURIComponent(ciphertext));
            // }}
          />

          <div style={{ height: "2.7vw" }} />
          {/* <div className="follow_us_wrapper">
            <p className="follow_title">Follow us at</p>
          </div>
          <SocialMedia /> */}
        </div>
      </FormCard>

      <AnimatedModal modalOpen={ShowOTP} className="otp_modal_adjust">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postOTP(otp_);
            // //in api success add this
            // setShowOTP(false);
            // setShowResetPassword(true);
          }}
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>

      <AnimatedModal modalOpen={ShowResetEmail}>
        <div
          className="close_otp_modal"
          onClick={() => {
            setShowResetEmail(false);
            setEmail("");
          }}
        >
          {closeIcon()}
        </div>
        <h1 className="modal_head">Reset Password</h1>
        <h1 className="modal_sub">
          Enter your email address to receive the Reset-Password link.
        </h1>
        <InputBox
          id="reset_email_Address"
          header="Email Address"
          value={Email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onFocus={() => {
            var ele = document.querySelector("#reset_email_Address");
            ele.classList.remove("error_shake", "input__error");
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              postResetEmail();
            }
          }}
        />

        <div className="res_success_btn">
          <Button type="gradient" bg name="Submit" onClick={postResetEmail} />
        </div>
      </AnimatedModal>
      <ResetPassword
        ShowResetPassword={ShowResetPassword}
        handlePassword={(data) => {
          postResetUserPassword(data);
        }}
        setConfirm={setConfirm}
      />

      <AnimatedModal
        modalOpen={Confirm}
        onConfirm={() => {
          encryptStorage?.removeItem("UDATA");
          encryptStorage?.removeItem("VID");
          setConfirm(false);
          setPassword("");
          setShowResetPassword(false);
        }}
        handleClose={() => {
          setConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const Social = [
  {
    icon: (
      <svg
        id="svgviewer-output_8_"
        data-name="svgviewer-output (8)"
        xmlns="http://www.w3.org/2000/svg"
        width="23.454"
        height="24"
        viewBox="0 0 23.454 24"
      >
        <path
          id="Path_130"
          data-name="Path 130"
          d="M5.266,9.765A7.077,7.077,0,0,1,12,4.909a6.937,6.937,0,0,1,4.418,1.582L19.909,3A11.99,11.99,0,0,0,1.24,6.65Z"
          fill="#ea4335"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M16.041,18.013A7.4,7.4,0,0,1,12,19.091a7.077,7.077,0,0,1-6.723-4.823l-4.04,3.067A11.965,11.965,0,0,0,12,24a11.43,11.43,0,0,0,7.834-3Z"
          fill="#34a853"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M19.834,21a11.954,11.954,0,0,0,3.62-9,9.951,9.951,0,0,0-.273-2.182H12v4.636h6.436a5.408,5.408,0,0,1-2.4,3.558Z"
          fill="#4a90e2"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M5.277,14.268a7.175,7.175,0,0,1-.011-4.5L1.24,6.65a12.14,12.14,0,0,0,0,10.685Z"
          fill="#fbbc05"
        />
      </svg>
    ),
    name: "google",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.677"
        height="24"
        viewBox="0 0 12.677 24"
      >
        <path
          id="svgviewer-output_9_"
          data-name="svgviewer-output (9)"
          d="M10.614,26V15.4H7V10.982h3.614V7.448C10.614,3.8,12.979,2,16.31,2a31.814,31.814,0,0,1,3.367.168V5.984h-2.31c-1.812,0-2.234.842-2.234,2.077v2.92h4.518l-.9,4.417H15.132L15.2,26"
          transform="translate(-7 -2)"
          fill="#3b5998"
          fillRule="evenodd"
        />
      </svg>
    ),
    name: "facebook",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.602"
        height="23.995"
        viewBox="0 0 24.602 23.995"
      >
        <path
          id="svgviewer-output_10_"
          data-name="svgviewer-output (10)"
          d="M13.3,1A12.3,12.3,0,0,0,9.412,24.973c.615.113.84-.267.84-.593,0-.292-.011-1.065-.017-2.092-3.422.743-4.144-1.649-4.144-1.649a3.258,3.258,0,0,0-1.366-1.8c-1.117-.763.085-.748.085-.748A2.583,2.583,0,0,1,6.695,19.36a2.619,2.619,0,0,0,3.58,1.022,2.629,2.629,0,0,1,.781-1.644c-2.731-.31-5.6-1.366-5.6-6.08a4.754,4.754,0,0,1,1.266-3.3A4.42,4.42,0,0,1,6.84,6.1s1.032-.331,3.382,1.261a11.645,11.645,0,0,1,6.159,0C18.73,5.771,19.76,6.1,19.76,6.1a4.421,4.421,0,0,1,.123,3.255,4.746,4.746,0,0,1,1.264,3.3c0,4.726-2.876,5.766-5.616,6.07a2.938,2.938,0,0,1,.834,2.278c0,1.644-.015,2.971-.015,3.374,0,.329.222.712.846.592A12.3,12.3,0,0,0,13.3,1"
          transform="translate(-1 -1)"
          fill="#333"
          fillRule="evenodd"
        />
      </svg>
    ),
    name: "github",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.523"
        height="23.994"
        viewBox="0 0 29.523 23.994"
      >
        <path
          id="svgviewer-output_11_"
          data-name="svgviewer-output (11)"
          d="M29.523,4.84a12.093,12.093,0,0,1-3.479.953,6.077,6.077,0,0,0,2.663-3.351,12.136,12.136,0,0,1-3.847,1.471A6.061,6.061,0,0,0,14.54,9.437,17.19,17.19,0,0,1,2.056,3.108,6.064,6.064,0,0,0,3.93,11.2a6.072,6.072,0,0,1-2.744-.758v.075a6.06,6.06,0,0,0,4.859,5.939,6,6,0,0,1-1.6.213,6.106,6.106,0,0,1-1.14-.108,6.065,6.065,0,0,0,5.659,4.207,12.161,12.161,0,0,1-7.522,2.592A12.446,12.446,0,0,1,0,23.272a17.154,17.154,0,0,0,9.285,2.721c11.141,0,17.233-9.23,17.233-17.234q0-.393-.017-.782A12.317,12.317,0,0,0,29.523,4.84"
          transform="translate(0 -2)"
          fill="#1da1f2"
          fillRule="evenodd"
        />
      </svg>
    ),
    name: "twitter",
  },
];
const SocialMedia = () => {
  return (
    <div className="social_wrapper">
      {Social.map((item) => (
        <div key={item.name} className={"social_icon " + item.name + "_icon"}>
          {item.icon}
        </div>
      ))}
    </div>
  );
};

const emailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.591"
    height="21.591"
    viewBox="0 0 21.591 21.591"
    className="input_icon"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="_36_Background_Mask_"
          data-name="36 (Background/Mask)"
          width="21.591"
          height="21.591"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="_36" data-name="36" clipPath="url(#clipPath)">
      <g
        id="Group_1683"
        data-name="Group 1683"
        transform="translate(3.356 4.646)"
      >
        <path
          id="Vector"
          d="M1.823,11.587A1.829,1.829,0,0,1,0,9.764V.912A.914.914,0,0,1,.912,0h13.3a.914.914,0,0,1,.912.912V9.764A1.829,1.829,0,0,1,13.3,11.587Z"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0,6.737,4.686a.893.893,0,0,0,1.012,0L14.486,0"
          transform="translate(0.319 0.574)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
      </g>
    </g>
  </svg>
);

const passIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.591"
    height="21.591"
    viewBox="0 0 21.591 21.591"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="_35_Background_Mask_"
          data-name="35 (Background/Mask)"
          width="21.591"
          height="21.591"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="_35" data-name="35" clipPath="url(#clipPath)">
      <g
        id="Group_1696"
        data-name="Group 1696"
        transform="translate(4.359 3.158)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M1.823,9.253A1.829,1.829,0,0,1,0,7.43V1.823A1.829,1.829,0,0,1,1.823,0H11.888a1.829,1.829,0,0,1,1.823,1.823V7.43a1.829,1.829,0,0,1-1.823,1.823Z"
            transform="translate(0 6.637)"
            fill="none"
            stroke="#aaa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
            strokeDasharray="0 0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M8.15,6.637V4.075a4.075,4.075,0,1,0-8.15,0V6.637"
            transform="translate(2.781)"
            fill="none"
            stroke="#aaa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
            strokeDasharray="0 0"
          />
        </g>
        <path
          id="Vector-3"
          data-name="Vector"
          d="M1.112,2.224A1.112,1.112,0,1,1,2.224,1.112,1.112,1.112,0,0,1,1.112,2.224Z"
          transform="translate(5.743 9.034)"
          fill="#aaa"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0V1.805"
          transform="translate(6.856 10.867)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
      </g>
    </g>
  </svg>
);
